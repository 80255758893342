import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FiTag, FiTriangle } from 'react-icons/fi';

const HomeEnd = () => {

  return (
    <Row className="pt-5 mb-5 home-info">
      <Col sm="12" md="6">
        <Card className="card-body text-center mb-3">
          <div className="m-3"><FiTag size="2.5rem" /></div>
          <h4>Find anything you want</h4>
          <p className="h1">Start Buying</p>
          <Link to="/search?q=&sortby=latest_product" className="btn btn-primary mx-auto mb-3">Search Now</Link>
        </Card>
      </Col>
      <Col sm="12" md="6">
        <Card className="card-body text-center mb-3">
          <div className="m-3"> <FiTriangle size="2.5rem" /></div>
          <h4>100% refunds</h4>
          <p className="h1">No question refund</p>
          <Link to="/refund-rules" className="btn btn-primary mx-auto mb-3">Read More</Link>
        </Card>
      </Col>
    </Row>
  )
}

export default HomeEnd;