import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import toast from 'react-hot-toast';

function Subscribe() {

  
  const [email, setEmail] = useState("")

  const handleSubmit = e => {

    e.preventDefault();

    if (email === "") {
      toast.error("The email field is required.");
      return;
    }

    window.localStorage.setItem("axios_disabled", '/newsletter/subscribe');
    window.axios.post('/newsletter/subscribe', { email: email })
      .then(r => {
        setEmail("");

      }).catch(e => {

        if(e.response && e.response.data){
          toast.error(e.response.data.message);
        }
      });
  }

  return (
    <section className="subscribe-area">
      <Container>
        <Row>
          <Col lg="12">
            <div className="subscribe-box">
              <Row className="align-items-center">
                <Col lg="6" className="d-none d-sm-none d-md-block">
                  <div className="subscribe-info px-5">
                    <h3 className="title">Need Consultation</h3>
                    <ul>
                      <li><a href={`tel:+801856120120`}>Phone: +8801856120120</a></li>
                      <li><a href={`mailto:support@ducor.net`}>Email: support@ducor.net</a></li>
                    </ul>
                  </div>
                </Col>
                <Col lg="6">
                  <div className="subscribe-info px-5">
                    <h3 className="title">Need Consultation</h3>
                    <form action="/newsletter/subscribe" onSubmit={(e) => handleSubmit(e)}>
                      <div className="input-box">
                        <input type="email" value={email} onChange={e=> setEmail(e.target.value)} placeholder="Email" />
                        <button type="submit">Subscribe</button>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Subscribe;