import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { FiAlignJustify, FiSearch, FiShoppingBag, FiUser } from 'react-icons/fi'
import {FaBars} from 'react-icons/fa';
import { Dropdown, Navbar } from 'react-bootstrap'
import { useStore } from '../../provides/app'
import SearchBox from './search'
import Cart from '../../includes/cart'
import AuthComponent from '../auth'
import { useAuth } from '../../support/auth'
import { useDucor } from '../../support/ducor'

function Header () {
  const [showAuth, setShowAuth] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showCart, setShowCart] = useState(false)
  const { isGuest } = useAuth()
  const { store, dispatch } = useStore()
  const location = useLocation()
  const ducor = useDucor()

  let path = location.pathname.split('/')
  const [stickyClass, setStickyClass] = useState('')

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar)

    return () => {
      window.removeEventListener('scroll', stickNavbar)
    }
  }, [])

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY
      windowHeight > 110 ? setStickyClass(' sticky') : setStickyClass('')
    }
  }

  // console.log('[path]', path);
  if (path[1] !== 'backend' || path[2] === undefined) {
    if (path[1] === 'settings') {
      path = 'settings'
    } else if (path[1] === 'account') {
      path = 'account'
    } else if (path[1] === 'downloads') {
      path = 'downloads'
    } else if (path[1] === undefined) {
      path = ''
    } else {
      path = path[1]
    }
  } else {
    path = path[2]
  }

  const toggleSearch = e => {
    e.preventDefault()
    if (!window.navigator.onLine) return

    if (path !== 'search') {
      if (showSearch === false) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
      setShowSearch(!showSearch)
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const logout = () => {
    window.axios
      .post('/logout')
      .then(response => {
        dispatch({ type: 'AUTH_LOGOUT' })
        setShowAuth(false)
      })
      .catch(function (error) {
        // handle error
        if (error && error.response && error.response.status === 401) {
          dispatch({ type: 'AUTH_LOGOUT' })
          setShowAuth(false)
        }
      })
  }

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  ))

  return (
    store &&
    store.settings &&
    store.menu && (
      <React.Fragment>
        <header className='header-area'>
          <div className='header-nav'>
            <div className={`navigation${stickyClass}`}>
              <Navbar collapseOnSelect expand='lg' variant='light' sticky='top'>
                <Navbar.Brand as={Link} to='/'>
                  <img
                    src='/img/logo.png'
                    className='custom-logo d-none d-md-block'
                    alt='ducor logo'
                  />
                  <img
                    src='/img/logo-sm.png'
                    className='mobile-logo m-2 d-md-none d-lg-none d-xl-none d-xxl-none'
                    alt='logo-sm logo'
                  />
                </Navbar.Brand>

                <Navbar.Collapse
                  id='responsive-navbar-nav'
                  className='sub-menu-bar'
                >
                  <div className='me-auto'>
                    <Dropdown>
                      <Dropdown.Toggle className="border bg-white" variant="link" id="dropdown-categories">
                       <FaBars/> <span className="mx-2  my-3">Categories</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Navbar.Collapse>
                <div className='navbar-social ms-auto d-flex align-items-center order-lg-4'>
                  <ul className='m-0'>
                    <li>
                      <Navbar.Toggle aria-controls='responsive-navbar-nav'>
                        <FiAlignJustify size='1.6em' />
                      </Navbar.Toggle>
                    </li>
                    <li className='d-none d-md-inline'>
                      <button
                        onClick={e => {
                          toggleSearch(e)
                        }}
                        to='/search'
                      >
                        <FiSearch size='1.6em' />
                      </button>
                    </li>
                    {isGuest ? (
                      <li>
                        <button onClick={() => setShowAuth(true)}>
                          <FiUser size='1.6em' />
                        </button>
                      </li>
                    ) : (
                      <Dropdown as='li'>
                        <Dropdown.Toggle as={CustomToggle} id='8e7'>
                          <FiUser size='1.6em' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className={
                            ducor.dir === 'ltr'
                              ? 'dropdown-menu-end'
                              : 'dropdown-menu-start'
                          }
                        >
                          <Dropdown.Item
                            as={Link}
                            to='/account'
                            className={path === 'account' ? 'active' : ''}
                          >
                            Account
                          </Dropdown.Item>
                          <Dropdown.Item
                            as={Link}
                            to='/downloads'
                            className={path === 'downloads' ? 'active' : ''}
                            eventKey='9'
                          >
                            Downloads
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              logout()
                            }}
                            eventKey='10'
                          >
                            Logout
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <li>
                      <button
                        onClick={() => {
                          window.scrollTo(0, 0);
                          setShowCart(true);
                        }}
                        className='position-relative'
                      >
                        <FiShoppingBag size='1.6em' />
                        <span className='main-header-badge'>
                          {store.cart ? store.cart.length : '0'}
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </Navbar>
            </div>
          </div>
          <div className='header-space'></div>
          {window.navigator.onLine ? (
            <React.Fragment>
              <SearchBox
                show={path === 'search' || showSearch}
                onClick={e => toggleSearch(e)}
              />
              <Cart
                show={showCart}
                onHide={() => setShowCart(false)}
                showAuth={() => {
                  setShowCart(false)
                  setShowAuth(true)
                }}
              />
              <AuthComponent
                show={showAuth}
                onHide={() => setShowAuth(false)}
              />
            </React.Fragment>
          ) : null}
        </header>
      </React.Fragment>
    )
  )
}

export default Header
