import { createBrowserRouter  } from "react-router-dom";
import DefaultLayout from "../layouts/default";
import Home from "../pages/home";
import About from "../pages/about";
import Contact from '../pages/contact';
import Refund from "../pages/refund";
import Terms from "../pages/terms";
import Privacy from "../pages/privacy";
import Error404 from "../pages/error/Error404";
import License from "../pages/license";

const routers = createBrowserRouter([
    {
      element: <DefaultLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/about",
          element: <About />,
        },
        {
          path: "/contact-us",
          element: <Contact />,
        },
        {
          path: "/support",
          element: <Contact />,
        },
        {
          path: "about",
          element: <Home />,
        },
        {
          path: "about",
          element: <Home />,
        },
        {
          path: "about",
          element: <Home />,
        },
        {
          path: "about",
          element: <Home />,
        },
        {
          path: "/licenses",
          element: <License />,
        },
        {
          path: "/privacy-policy",
          element: <Privacy />,
        },
        {
          path: "/terms-conditions",
          element: <Terms />,
        },
        {
          path: "/refund-returns",
          element: <Refund />,
        },
        {
          path: "*",
          element: <Error404 />,
        },
      ],
    },
  ]);

export default routers;