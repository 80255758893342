
function Ducor() {
    return {};
}

export function Get() {

    return {
        container: "wide",
        dir: 'ltr',
        header_placement: 'sticky-top',
        header_style: 'color_1',
        dark_mode: false,
        
    };
}


export default Ducor;

Ducor.Get = Get;
