import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaSkype,
  FaWhatsapp
} from 'react-icons/fa';

export default function Footer () {

  const {pathname} = useLocation();

  return (
    <React.Fragment>
      <footer className='footer-area'>
        <Container>
          <Row>
            <Col lg='4' md='6' sm='12'>
              <div className='footer-content'>
                <Link to='/?ref=footer'>
                  <img
                    src="/img/logo-footer.png"
                    alt="Ducor Footer Logo"
                  />
                </Link>
                <div className='footer-txt'>
                  High quality Jekyll, React , Nextjs, Vue PHP Script Template created by our team
                </div>
                <ul>
                    <li>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href="https://facebook.com/ducorteam"
                      >
                        <FaFacebookF size='1.1em' />
                      </a>
                    </li>
                    <li>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href="https://twitter.com/ducorteam"
                      >
                        <FaTwitter size='1.1em' />
                      </a>
                    </li>
  
                    <li>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href="https://youtube.com/ducorteam"
                      >
                        <FaYoutube size='1.1em' />
                      </a>
                    </li>
                    <li>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href="https://join.skype.com/invite/UW2jzLEim4q0"
                      >
                        <FaSkype size='1.1em' />
                      </a>
                    </li>
                    <li>
                      <a
                        rel='noreferrer'
                        target='_blank'
                        href="whatsapp://tel:+8801856120120"
                      >
                        <FaWhatsapp size='1.1em' />
                      </a>
                    </li>
                    
                </ul>
              </div>
            </Col>
            <Col lg='2' md='6' sm='6'>
              <div className='footer-list mt-30'>
                <h3 className='title'>Pages</h3>
                <ul>
                  <li>
                    <Link to='/about'>About</Link>
                  </li>
                  <li>
                    <Link to='/contact-us'>Support</Link>
                  </li>
                  <li>
                    <Link to='/contact-us'>Refund</Link>
                  </li>
                  <li>
                    <Link to='/contact-us'>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg='3' md='6' sm='6'>
              <div className='footer-list item-2 mt-30 pl-45'>
                <h3 className='title'>Popular Links</h3>
                <ul>
                  <li>
                    <Link to='/categories/react'>React Template</Link>
                  </li>
                  <li>
                    <Link to='/categories/admin-template'>Admin Template</Link>
                  </li>
                  <li>
                    <Link to='/categories/php-script'>PHP Script</Link>
                  </li>
                  <li>
                    <Link to='/categories/jekyll'>Jekyll Template</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg='3' md='6'>
              <div className='footer-instagram'>
                <h3 className='title'>Address</h3>
                <div className='mb-3'>
                  <div className='footer-txt'>
                    20/c House#08 Dhaka-1230, Bangladesh
                  </div>
                </div>
                <div className='mb-3'>
                  <a
                    target='_blank'
                    className='footer-txt'
                    rel='noreferrer'
                    href={`mailto:support@ducor.net`}
                  >
                    support@ducor.net
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className='footer-copyright'>
          <Container>
            <Row>
              <Col lg='12'>
                <div className='footer-copyright-item'>
                  <p>&copy; 2020- {new Date().getFullYear()} Ducor All Rights Reserved. </p>
                  <ul>
                    <li>
                      <Link to='/refund-returns' className={pathname.includes('/refund-returns')? 'active': ''}>Refund Rules</Link>
                    </li>
                    <li>
                      <Link to='/terms-conditions' className={pathname.includes('/terms-conditions')? 'active': ''}>Terms and Conditions</Link>
                    </li>
                    <li>
                      <Link to='/privacy-policy' className={pathname.includes('/privacy-policy')? 'active': ''}>Privacy Policy</Link>{' '}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </React.Fragment>
  )
}
