import { useStore } from "../provides/app";

/**
 * 
 * @returns 
 */
 export function useEnv() {
    const { store } = useStore();
    return store.env;
}
