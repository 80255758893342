import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from "react-router-dom";
import { AppProvider } from './provides/app';
import reportWebVitals from './reportWebVitals';
import ItemProvider from './provides/Item';
import Spinner from './includes/spinner';
import routers from './routes';

import './sass/index.scss';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProvider>
        <ItemProvider>
          <RouterProvider
            router={routers}
            fallbackElement={<Spinner />}
          />
        </ItemProvider>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('app')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
