import React, { createContext, useReducer, useContext, useEffect, useState, useCallback } from "react";
import Auth from "./reducers/auth";
import AuthToken from "./reducers/auth_token";
import Locale from "./reducers/locale";
import Setting from './reducers/setting';
import Ducor from './reducers/ducor';
import axios from "axios";

// initaial values
const initialState = {
    installed: undefined,
    guestId: Auth.GuestId(),
    env: [],
    auth: Auth(),
    token: AuthToken(),
    cart: [],
    tax: 0,
    settings: Setting.Get(),
    locale: Locale.Get(),
    locales: [],
    menu: {},
    ducor: Ducor.Get(),
    onLine: window.navigator.onLine,
    error: {
        status: window.navigator.onLine ? undefined : "Offline",
        statusText: !window.navigator.onLine ? undefined : "You are offline",
    },
    isLoading: false,
    reload_required: false,
};

/**
 * create context
 */
const Context = createContext([{}, () => { }]);

/**
 * 
 * @param {*} props 
 * @returns 
 */
function Provider(props) {

    const [init, setInit] = useState(false);

    const reducer = (state, action) => {

        switch (action.type) {
            case 'SET_INSTALLED':
                return {
                    ...state,
                    installed: action.installed,
                };
            case 'SET_ENV':
                return {
                    ...state,
                    env: action.env,
                };
            case 'SET_CART':
                return {
                    ...state,
                    cart: action.cart,
                };
            case 'TAX_SET':
                return {
                    ...state,
                    tax: action.tax,
                };
            case 'AUTH_UPDATE':
                return {
                    ...state,
                    auth: Auth.Update(action.data),
                };
            case 'AUTH_LOGIN':
                return {
                    ...state,
                    auth: Auth.Login(action.data),
                };
            case 'AUTH_LOGOUT':
                return {
                    ...state,
                    auth: Auth.Logout(),
                    cart: [],
                    guestId: Auth.GuestId(true),
                };
            case 'AUTH_TOKEN_UPDATE':
                return {
                    ...state,
                    token: AuthToken.Update(action.token),
                };
            case 'UPDATE_STORAGE':
                return {
                    ...state,
                    guestId: Auth.GuestId(),
                    auth: Auth(),
                };
            case 'SETTING_UPDATE':

                if (!action.key && !action.value) {
                    return state;
                }
                if (state.settings[action.key] === undefined) {
                    return state;
                }

                state.settings[action.key] = action.value;
                return state;
            case 'SETTING_SET':

                if (action.settings["billing_currency"]) {
                    window.store_currency = action.settings["billing_currency"];
                }
                return {
                    ...state,
                    settings: action.settings
                }
            case 'UPDATE_LANG':

                if (!action.key && !action.value) {
                    return state;
                }
                state.lang[action.key] = action.value;
                return state;
            case 'LANG_SET':
                return {
                    ...state,
                    lang: action.lang
                }
            case 'UPDATE_MENU':
                if (!action.key && !action.value) {
                    return state;
                }
                state.menu[action.key] = action.value;
                return state;
            case 'SET_MENU':
                return {
                    ...state,
                    menu: action.menus,
                }

            case 'UPDATE_CUSTOMIZEABLE':
                return {
                    ...state,
                    customizeable: action.status
                }

            case 'LOCALES_SET':
                return {
                    ...state,
                    locales: action.locales,
                }

            case 'LOCALE_SET':
                window.localStorage.setItem('dscart_locale', action.locale);

                return {
                    ...state,
                    locale: action.locale,
                }

            case 'DUCOR_SET':
                return {
                    ...state,
                    ducor: action.ducor,
                }

            case "SET_ERROR":
                return {
                    ...state,
                    error: action.error,
                }

            case "SET_ONLINE":
                return {
                    ...state,
                    online: action.online,
                }
            case "SET_RELOAD":
                return {
                    ...state,
                    reload_required: action.reload,
                }
            case "SET_LOADING":
                return {
                    ...state,
                    isLoading: action.isLoading,
                }
            default:
                return state;
        }
    }

    const [store, dispatch] = useReducer(reducer, initialState);

    /**
     * Navigator.onLine
     * Navigator.offLine
     * @return boolean
     */
    const handleNetworkChange = (e) => {

        if (!window.navigator.onLine) {
            dispatch({
                type: 'SET_ERROR',
                error: {
                    status: window.navigator.onLine ? undefined : "Offline",
                    statusText: !window.navigator.onLine ? undefined : "You are offline",
                }
            });
        }

        dispatch({
            type: 'SET_ONLINE',
            onLine: window.navigator.onLine
        });

    }

    // online offline
    useEffect(() => {
        window.addEventListener('online', handleNetworkChange);
        window.addEventListener('offline', handleNetworkChange);

        return () => {
            window.removeEventListener('online', handleNetworkChange);
            window.removeEventListener('offline', handleNetworkChange);
        };

    }, []);

    /**
     * 
     * @param {*} e 
     */
    const handleStorageUpdate = (e) => {

        const db = ['dscart_cart', 'dscart_guest_id', 'dscart_auth', 'dslocale'];

        if (db.includes(e.key)) {
            dispatch({
                type: 'UPDATE_STORAGE',
                key: e.key,
                newValue: e.newValue
            });
        }
    }

    /**
     * 
     */
    useEffect(() => {
        window.addEventListener("storage", handleStorageUpdate);

        return () => window.removeEventListener("storage", handleStorageUpdate);

    }, []);


    const initalize = useCallback(() => {
        if (init === false) {

            let url = '/init';
            const currentLocale = window.localStorage.getItem('dscart_locale');
            if (currentLocale) {
                url = '/init?locale=' + currentLocale;
            }

            axios.get(url, {
                withCredentials: false,
                baseURL: window.location.origin,
            }).then(response => {

                if (response.data) {

                    const init = response.data;

                    init.installed !== undefined && dispatch({
                        type: 'SET_INSTALLED',
                        installed: init.installed,
                    });

                    init.env && dispatch({
                        type: 'SET_ENV',
                        env: init.env,
                    });

                    init.settings && dispatch({
                        type: 'SETTING_SET',
                        settings: init.settings,
                    })

                    init.menus && dispatch({
                        type: 'SET_MENU',
                        menus: init.menus,
                    })

                    init.env && dispatch({
                        type: 'TAX_SET',
                        tax: init.tax,
                    })

                    init.cart && dispatch({
                        type: 'SET_CART',
                        cart: init.cart,
                    })

                    init.locales && dispatch({
                        type: 'LOCALES_SET',
                        locales: init.locales,
                    });

                    init.locale && dispatch({
                        type: 'LOCALE_SET',
                        locale: init.locale,
                    });
                }

            }).catch(error => {
                // console.log('error', error);
                if (error.response === undefined || error.response === null) {
                    dispatch({
                        type: 'SET_ERROR',
                        error: {
                            status: 'Offline',
                            statusText: "You are offline",
                        },
                    });
                }
            }).finally(function () {

            });
        }

        setInit(true);
    }, [init])


    /**
     * get data from server
     */
    useEffect(() => {
        initalize();

    }, [initalize]);


    useEffect(() => {

        if (store.reload_required) {
            window.location.reload();
        }

    }, [store.reload_required]);

    return (
        <Context.Provider value={{ store, dispatch }}>
            {props.children ? props.children : null}
        </Context.Provider>
    )
}

/**
 * get full store
 * 
 * @returns 
 */
export function useStore() {
    return useContext(Context);
}


/**
 * 
 * @returns 
 */
export function usePage() {
    const { store, dispatch } = useStore();
    return { pages: store.pages, dispatch };
}

/**
 * 
 */
export {
    Provider as AppProvider,
    Context as AppContext,
};

export default Provider;