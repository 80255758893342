import React, { Fragment, useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function RegisterComponent(props) {

    const [formDisable, setDisableForm] = useState(false);
    const [data, setData] = useState({});

    const handleSubmit = event => {

        event.preventDefault();
        setDisableForm(true);
        setData({});


        window.axios.post("/register", {
            email: event.currentTarget.email.value,
        }).then(res => {

            setData({
                success: res.data.message
            });

            setTimeout(() => {
                props.updateTab('login');
            }, 2000);

        }).catch(function (error) {
            // error
            if (error.response && error.response.status === 422) {
                setData({ error: error.response.data.errors.email[0] });
            }
        }).then(function () {
            setDisableForm(false);
        });
    }

    return (
        <div className="m-3">
            <h2 className="mb-3 text-center">Signup</h2>
            <hr />
            {data.success && (
                <Alert variant="primary">
                    {data.success}<br />
                    {/* <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> */}
                    Please Login
                </Alert>
            )}
            {data.error && (
                <Alert variant="danger"> {data.error} </Alert>
            )}
            <Form onSubmit={(e) => { handleSubmit(e) }}>
                <Form.Group className="mb-4 pb-md-2" controlId="emailFaild">
                    <Form.Label>Email</Form.Label>
                    <Form.Control name="email" placeholder="email" disabled={formDisable} />
                    <small>A password will be sent to your email address</small>
                </Form.Group>

                <div className="mb-4 pb-md-2">
                    <p>
                        Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our
                        <Link to="/privacy-policy"> Privacy Policy</Link>.
                    </p>
                </div>
                <div className="d-grid">
                    <Button type="submit" className="btn-xl btn-primary" disabled={formDisable}>
                        {formDisable && data.success === undefined ? (
                            <Fragment>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                 Loading...
                            </Fragment>
                        ) : (
                            <span>Signup</span>
                        )}
                    </Button>
                </div>

                <div className="border-top border-gray-200 pt-3 pt-sm-4 text-center">
                    <span className="text-gray-700">
                        Already have an account?
                        <Link onClick={(e) => { props.updateTab('login'); e.preventDefault() }} to="/login" className="link-primary"> Sign in</Link>
                    </span>
                </div>
            </Form>
        </div>
    )
}
