/**
 * Auth api token
 */
export const AuthToken = () => {

    if(window.localStorage.getItem('dscart_auth_token')){
        return window.localStorage.getItem('dscart_auth_token');
    }
    return "";
}
/**
 * update Auth api token
 */
export const AuthTokenUpdate = (token) => {

    window.localStorage.setItem("dscart_auth_token", token);
    return AuthToken();
}

AuthToken.Update = AuthTokenUpdate;

export default AuthToken;
