import React, { useState, Fragment } from 'react'
import { Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function ForgotPasswordComponent(props) {

    const [formDisable, setFormDisable] = useState(false);
    const [data, setData] = useState({});

    const handleSubmit = (event) => {

        setFormDisable(true);
        setData({});
        event.preventDefault();
        const form = event.currentTarget;

        window.axios.post("/forgot-password", {
            email: form.email.value,
        }).then(res => {
            setData({
                success: res.data.message
            });

        }).catch(function (error) {
            // error
            if (error.response && error.response.status === 422) {
                setData({
                    error: error.response.data.errors.email[0],
                });
            }
            
        }).finally(function(){
            setFormDisable(false);
        })
    }

    return (
        <div className="m-3">
            <h2 className="mb-3 text-center">Lost your password?</h2>
            <hr />
            {data.success && (
                <Alert variant="primary"> {data.success}</Alert>
            )}
            {data.error && (
                <Alert variant="danger"> {data.error}</Alert>
            )}
            <p>Change your password following instructions below. This helps to keep your new password secure.</p>
            <ul>
                <li>Please enter your email address</li>
                <li>You will receive a link to create a new password via email.</li>
            </ul>
            <Form className="pt-3" onSubmit={handleSubmit}>
                <Form.Group className="mb-4 pb-md-2">
                    <Form.Label>Email</Form.Label>
                    <Form.Control name="email" disabled={formDisable} />
                </Form.Group>

                <div className="d-grid">
                    <Button type="submit" className="btn-xl btn-primary" disabled={formDisable || data.success === null}>
                        {formDisable && data.success === undefined ? (
                            <Fragment>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                {' '}  Loading...
                            </Fragment>
                        ) : (
                            <span>Send account recovery email</span>
                        )}
                    </Button>
                </div>

            </Form>
            <div className="pt-3 pt-sm-4 text-center">
                <span className="text-gray-700">
                   {"Back to login "}
                    <Link onClick={(e) => { props.updateTab('register'); e.preventDefault() }} to="/login" className="link-primary"> Login</Link></span>
            </div>
        </div>
    )
}
