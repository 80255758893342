import React from "react";
import { Container, Row, Col } from "react-bootstrap";

function Error404(){

    return (
        <section className="page">
            <Container fluid>
                <Row className="py-3 px-5 mb-4 border-bottom">
                    <Col><h1>Page Not Found</h1></Col>
                </Row>
                <Row className="px-5 py-3">
                    <Col>
                        Page not Found
                    </Col>
                </Row>
            </Container>
        </section>
    );

}

export default Error404;