import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function __(key, optons = []) {
    return trans(key, [])
}

export function trans(key, optons = []) {

    return [];
}


export function __currency(value = 0) {

    if (window.store_currency === undefined) {
        window.store_currency = "USD";
    }

    return value.toLocaleString('en-US', {
        style: 'currency', currency: window.store_currency,
    });
};

export function url(path = "") {

    return window.SITE_URL + "/" + path
}


export const delay = (ms = 3000) => new Promise(
    resolve => setTimeout(resolve, ms)
);

export function usePageNum() {
    const { search } = useLocation();
    const [page, setPage] = useState(1);
  
    useEffect(() => {
        const q = new URLSearchParams(search)
        setPage(q.get('page') ? q.get('page') : 1);
    }, [search]);

    return page;
}