import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import SeoForPage from '../../includes/seo'

export default function Refund() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  return (
    <React.Fragment>
      <SeoForPage />
      <section className="page-title">
        <Container fluid={true}>
          <Row >
            <Col className="mx-5">
              <h1>Refund and Returns Policy</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-mission-area">
        <Container fluid={true}>
          <Row>
            <Col className="mx-5">
              <h1 id="refund-and-returns-policy">Refund and Returns Policy</h1>
              <p>Hi, we’re ducor jekyll theme and welcome to the ducor jekyll theme Refund Rules. these rules outline what you can expect from us of the items you buy. They apply to customers.</p>
              <p>The products that we sell on the ducor jekyll theme are digital goods and cannot be “returned”, so your entitlement to a refund is designed with this in mind. Of course, in addition to these rules, each country has its own laws surrounding refunds, and these local laws are not excluded if they apply to you.</p>
              <h2 id="before-you-ask-for-a-refund-from-us">Before you ask for a refund from us</h2>
              <p>If you have purchased an item from our “Web Themes and Templates” or “Code” from our site and you are experiencing a <strong><em>technical issue</em></strong> with the item we recommend that you <a href="https://help.market.envato.com/hc/en-us/articles/203039054">contact us</a> of that item and seek assistance. Often they’ll be able to help to troubleshoot your problem.</p>
              <h2 id="asking-for-refunds">Asking for refunds</h2>
              <p>We understand, however, that sometimes the we may not be able to solve your issue or there may be circumstances in which a refund is warranted. In this case, here are the circumstances in which we would expect to provide you with a refund:</p>
              <ul>
                <li>Item is “not as described” or the item doesn’t work the way it should.</li>
                <li>Item has a security vulnerability</li>
                <li>Item support is promised but not provided</li>
                <li>Item support extension not used</li>
                <li>Items that have not been downloaded</li>
                <li>Resolving disputes – asking to help</li>
              </ul>
              <h2 id="item-is-not-as-described-or-the-item-doesn-t-work-the-way-it-should-">Item is “not as described” or the item doesn’t work the way it should.</h2>
              <p>If an item doesn’t work the way it should then the author is required to promptly fix the issue by updating the item. An item is “not as described” if it is materially different from the item description or preview. If the issue can’t be fixed or it turns out that the item is “not as described” then you would be entitled to a refund from the author.</p>
              <h2 id="item-has-a-security-vulnerability">Item has a security vulnerability</h2>
              <p>If an item contains a security vulnerability and can’t easily be fixed you would be entitled to a refund from the us. If the item can be fixed, then we should do so promptly by updating the item. If the item contains a security vulnerability that is not patched in an appropriate timeframe then we would expect to provide a refund for the item.</p>
              <h2 id="item-support-is-promised-but-not-provided">Item support is promised but not provided</h2>
              <p>If we advertises their item as including item support, you have a current support entitlement, and you are not provided that support in accordance with the item support policy should provide you with a refund.</p>
              <h2 id="item-support-extension-not-used">Item support extension not used</h2>
              <p>If you purchase an item support extension and request a refund of that extension before your existing item support expires we would provide you with a refund of that item support extension.</p>
              <h2 id="items-that-have-not-been-downloaded">Items that have not been downloaded</h2>
              <p>If you have not downloaded a purchased item within 1 months from the date of purchase, you may be eligible for a refund. You will be required to provide that item your purchase code in order to verify your claim that you have not downloaded an item. Although we think 1 months is a reasonable time, depending on where you are located, you might have a longer period to claim a refund if you have purchased for personal use and haven’t downloaded the item.</p>
              <h2 id="resolving-disputes-asking-to-help">Resolving disputes – asking to help</h2>
              <p>If you can’t come to an agreement about a refund, you can raise a dispute. We may ask you to provide supporting documentation or evidence. We will make a decision based on all available information and you agree that our decision is final.</p>
              <p><strong>ducor jekyll theme</strong> are obliged to give policy refunds in any of the situations listed below.</p>
              <ul>
                <li>You don’t want it after you’ve downloaded it</li>
                <li>The item did not meet your expectations or you feel the item is of low quality</li>
                <li>You simply change your mind</li>
                <li>You bought an item by mistake</li>
                <li>You do not have sufficient expertise to use the item</li>
                <li>You claim that you are entitled to a refund but do not provide sufficient information as to why you are entitled to a refund</li>
                <li>You can no longer access the item because it has been removed or the author who previously provided the item is no longer active on our website (we advise you to download items as soon as you have purchased them to avoid this situation).</li>
              </ul>
              <p><a href="https://www.ducor.net/" /></p>
              <p>Contact us for questions related to refunds and returns.</p>
            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  )
}

