import React, { useRef, useState, useEffect } from 'react';
import { FiMapPin, FiMail, FiSmartphone } from "react-icons/fi";
import { Form, Row, Button, Container, Col } from 'react-bootstrap';

import SeoForPage from '../../includes/seo';

export default function Contact() {

  const [errors, setErrors] = useState({});
  const [formDisabled, setFormDisabled] = useState(false);
  const resetRef = useRef();
  
  useEffect(() => {
      window.scrollTo(0, 0)
  }, []);

  const onSubmit = e => {

    e.preventDefault();
    setErrors({});
    setFormDisabled(true);

    window.axios.post('/contact/page', new FormData(e.target))
      .then(r => {
        if (r && r.data && r.data.message) {

          resetRef.current.click();
        }
      }).catch(e => {
        if (e.response && e.response.data) {
          if (e.response.data.errors) {
            setErrors(e.response.data.errors)
          }
        }
      }).finally(function () {
        setFormDisabled(false);
      });
  }

  return (
    <React.Fragment>
      <SeoForPage />

      <section className="page-title">
        <Container fluid={true}>
          <Row >
            <Col className="mx-5">
              <h1>Contact Us</h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="page">
        <Container>
          <Row className="py-3 px-5 mb-3">
            <div className=" d-flex align-items-stretch  col-lg-4 col-md-12">
              <div className="w-100 d-flex align-items-center bg-white p-3 shadow-sm rounded">
                <div className="me-3">
                  <div className="p-3 rounded" style={{ backgroundColor: 'rgb(208, 250, 236)' }}>
                    <FiMapPin />
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Address</h5>
                  <span className="text-black">
                  20/c House#08 Dhaka-1230, Bangladesh
                  </span>
                </div>
              </div>
            </div>
            <div className=" d-flex align-items-stretch  col-lg-4 col-md-6 mt-3 mt-lg-0">
              <div className="w-100  d-flex align-items-center bg-white p-3 shadow-sm rounded">
                <div className="me-3">
                  <div className="p-3 rounded" style={{ backgroundColor: 'rgb(208, 250, 236)' }}>
                    <FiMail />
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Email Us</h5>
                  <a className="btn-link" href="mailto:support@ducor.net">support@ducor.net</a>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-stretch  col-lg-4 col-md-6 mt-3 mt-lg-0">
              <div className="w-100 d-flex align-items-center bg-white p-3 shadow-sm rounded">
                <div className="me-3">
                  <div className="p-3 rounded" style={{ backgroundColor: 'rgb(208, 250, 236)' }}>
                    <FiSmartphone />
                  </div>
                </div>
                <div>
                  <h5 className="mb-1">Call Us</h5>
                  <a href="tel:+8801856120120">+8801856120120</a>
                </div>
              </div>
            </div>
          </Row>

          <Row className="pt-3 px-5 justify-content-center">
            <div className="col-sm-12 col-lg-9">
              <div>
                <div className="text-center pb-3">
                  <h2><span className="font-w-4 d-block">Describe your project</span>
                    and leave us your contact info
                  </h2>
                  <p className="lead">Get in touch and let us know how we can help.</p>
                </div>
                <Form className="row" onSubmit={onSubmit}>

                  <Form.Group className="mb-3 col-md-6">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control name="first_name" isInvalid={errors.first_name !== undefined} />
                    {errors.first_name !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control name="last_name" isInvalid={errors.last_name !== undefined} />
                    {errors.last_name !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6">
                    <Form.Label>Email</Form.Label>
                    <Form.Control name="email" isInvalid={errors.email !== undefined} />
                    {errors.email !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.email[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 col-md-6">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control name="phone" isInvalid={errors.phone !== undefined} />
                    {errors.phone !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.phone[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3  col-sm-12">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control name="subject" isInvalid={errors.subject !== undefined} />
                    {errors.subject !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.subject[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-3 col-sm-12">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" name="message" className="h-auto" rows={4} isInvalid={errors.message !== undefined} />
                    {errors.message !== undefined && (
                      <Form.Control.Feedback type="invalid">
                        {errors.message[0]}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <div className="col-sm-12 text-end">
                    <Button disabled={formDisabled} ref={resetRef} onClick={() => setErrors({})} className="btn btn-primary" type="reset">Reset</Button>{' '}
                    <Button disabled={formDisabled} className="btn btn-primary" type="submit">
                      {formDisabled ? (
                        <React.Fragment>
                          <span className="spinner-border spinner-border-sm"></span>{' '}
                          <span>Loading...</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Send Messages
                        </React.Fragment>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
)}

