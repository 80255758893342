import React, { useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SeoForPage from '../../includes/seo'

export default function License() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <React.Fragment>
      <SeoForPage />
      <section className="page-title">
        <Container fluid={true}>
          <Row >
            <Col className="mx-5">
              <h1>License And Copyright</h1>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="about-mission-area">
        <Container fluid={true}>
          <Row>
            <Col className="mx-5">
              <div>
                <h2>Product Types:</h2>
                <h4>Premium/Commercial Licensed Products</h4>
                <ol>
                  <li><strong>Allowed</strong>&nbsp;to Remove Backlink/Credits.</li>
                  <li><strong>Allowed</strong>&nbsp;to Submit&nbsp;Support Tickets.</li>
                  <li>Documentation is&nbsp;<strong>provided</strong>.</li>
                  <li><strong>Allowed</strong>to use products&nbsp;for personal and commercial projects.</li>
                  <li><strong>Modify</strong>&nbsp;Products to fit your/clients needs and can charge your clients.</li>
                  <li><strong>Not allowed</strong>&nbsp;&nbsp;to&nbsp;resell, redistribute, license, or sub-license.</li>
                  <li>Use Depending on License Type.</li>
                </ol>
                <h4>Free/Personal Licensed Products</h4>
                <ol>
                  <li><strong>Not allowed</strong>&nbsp;to Remove Footer Credits.</li>
                  <li><strong>Allowed</strong>&nbsp;to use with&nbsp;personal projects only.</li>
                  <li><strong>Support is not provided</strong>&nbsp;for free downloads.</li>
                  <li><strong>Documentation is not Provided</strong>&nbsp;with Free Downloads.</li>
                  <li><strong>Not allowed</strong>&nbsp;to use with commercial projects and charging clients</li>
                </ol>
                <hr />
                <h2>License Types:</h2>
                <h4><strong>Single License</strong></h4>
                <ol>
                  <li>Use in&nbsp;<strong>Single(01)</strong>&nbsp;Commercial Project.</li>
                  <li>Charge&nbsp;<strong>Single(01)</strong>&nbsp;Client/Customer.</li>
                  <li>Use in&nbsp;<strong>Single(01)</strong>&nbsp;Project/App/Domain.</li>
                  <li>Normally, all products under single license, If there is nothing mentioned.</li>
                  <li>Best Choice for Small Businesses.</li>
                </ol>
                <h3><strong>Developer License</strong></h3>
                <ol>
                  <li>Use in&nbsp;<strong>Multiple/Unlimited</strong>&nbsp;Projects/Domains.</li>
                  <li>Charge&nbsp;<strong>Multiple/Unlimited</strong>&nbsp;Clients/Customers.</li>
                  <li>Best Choice for&nbsp;<strong>Multiple/Unlimited</strong>&nbsp;Projects/Apps/Websites/Clients/Domains.</li>
                </ol>
                <hr />
                <h4><strong>For Bloggers/Marketers</strong></h4>
                <ol>
                  <li><strong>Allowed</strong>&nbsp;to post about our Products in your blog.</li>
                  <li><strong>Not Allowed</strong>&nbsp;to provide direct download file.</li>
                </ol>
              </div>

            </Col>
          </Row>
        </Container>
      </section>

    </React.Fragment>
  )
}

