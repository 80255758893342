import React from 'react'
import { styled } from "goober";

export default  function Spinner() {
    const Div = styled('div')`
    height: 90vh !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 0 !important;
  `;

    return (
        <Div>
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </Div>
    )
}

