import { v4 as uuidv4 } from 'uuid';

/**
 * Auth support
 */
function Auth() {
    let storage = {};
    try {
        storage = JSON.parse(localStorage.getItem("dscart_auth")) || {};

        Object.keys(storage).length ? storage.check = true : storage.check = false;    
    } catch (error) {
        console.error(error);
    }
    return storage;
}

/**
 * login
 */
export const AuthLogin = (data) => {

    try {
        window.localStorage.setItem('dscart_auth', JSON.stringify(data));
    } catch (error) {
        console.error(error);
    }
    return Auth();
}

/**
 * login
 */
export function AuthLogout() {
    window.localStorage.removeItem('dscart_auth');
    window.localStorage.removeItem('dscart_cart');
    window.localStorage.removeItem('guest_id');
    window.localStorage.removeItem('dscart_auth_token');
    
    return Auth();
}

/**
 * login
 */
export const AuthUpdate = (data) => {

    localStorage.setItem("dscart_auth", JSON.stringify(data));
    
    return Auth();
}


export function GetGuestId(forceUpdate = false) {
    let storage = localStorage.getItem("dscart_guest_id");

    if(forceUpdate || storage === null || storage === "" ){
        storage = window.localStorage.setItem('dscart_guest_id', uuidv4())
    }

    return storage;
}



Auth.Login = AuthLogin;
Auth.Update = AuthUpdate;
Auth.Logout = AuthLogout;
Auth.GuestId = GetGuestId;

export default Auth;
