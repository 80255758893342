const { default: toast } = require('react-hot-toast');
window.axios = require('axios').default;

// Add a request interceptor
window.axios.interceptors.request.use(async (config) => {

    config.withCredentials = false;
    // Do something before request is sent
    config.baseURL = 'https://ducor.jubayed.com';
    config.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    config.headers.common['Accept'] = 'application/json';
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['timeout'] = 5000;

    // Authorization token 
    if (window.localStorage.getItem('dscart_auth_token')) {
        config.headers.common['Authorization'] = window.localStorage.getItem('dscart_auth_token');
    }

    return config;
});

// Add a response interceptor
window.axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    if (response && response.data && response.data.message) {
        toast.success(response.data.message);
    }
    return response;
}, function (error) {

    if (error.response === undefined) {
        toast.error('Api server not response');
    } else if ([500].includes(error.response.status)) {
        toast.error(error.response.statusText);
    } else if (error.response.status === 401 && window.location.pathname !== '/logout') {
        document.location.href = window.location.origin + "/logout";
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});

require('./bootstrap')
