import React from 'react'
import { Outlet } from 'react-router-dom';
import Footer from '../../includes/footer'
import Header from '../../includes/header'
import Subscribe from '../../includes/subscribe';
// import Error from '../backend/_error';

function DefaultLayout(props) {

    return (
        <React.Fragment>
            <Header />
            {window.navigator.onLine ? (
                <React.Fragment>
                    <Outlet/>
                    <Subscribe />
                    <Footer />
                </React.Fragment>
            ) : (
                <div>
                    ooff
                    {/* <Error error={{ status: "offLine" }} /> */}
                </div>
            )}
            {/* <Customizer /> */}
        </React.Fragment>
    );
}

export default DefaultLayout;
