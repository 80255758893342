import React from 'react';
import { Button } from "react-bootstrap";
import { FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useState } from 'react';
import { useCart } from '../../support/cart';
import { __currency } from '../../support/helper';
import toast from 'react-hot-toast';


function SingleItem(props) {

    const [cartFor, setCartFor] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { addToCart } = useCart();

    const handleAddToCart = (item) => {

        setIsLoading(true);
        setCartFor('regular');
        addToCart(item, 'regular').then(res => {
            // console.log();
        }).catch(error => {

            if (error.message) {
                toast.error(error.message,);
            }

        }).finally(function () {
            setIsLoading(false);
            setCartFor()
        });
    }

    return (
        <div className="ducor-item-single shadow-sm border">
            <div className="ducor-item-wraper">
                <div className="ducor-item-inner">
                    <div className="ducor-item-img position-relative">
                        {props.item.category && (
                            <div className="ducor-category d-inline-block text-truncate">
                                <Link to={`/categories/${props.item.category.slug}`} className="category-name">
                                    {props.item.category.name}
                                </Link>
                            </div>
                        )}
                        <Link to={`/item/${props.item.slug}`} className="item-preview-in">
                            Preview
                        </Link>
                        <Link to={`/item/${props.item.slug}`}>
                            <img src={props.item.previewImageUrl} alt={props.item.name} />
                        </Link>
                    </div>
                    <div className="ducor-item-content pos-rel">
                        <h3 className="ducor-name d-inline-block text-truncate">
                            <Link to={`/item/${props.item.slug}`}> {props.item.name}</Link>
                        </h3>
                        <div className="ducor-meta-info d-flex align-items-center">
                            <div className="ducor-meta-item">
                                <div className="ducor-price">{__currency(props.item.regular_price)}</div>
                            </div>
                            <div className="ducor-activity-btn">
                                <Button disabled={isLoading} onClick={() => handleAddToCart(props.item)}>
                                    {cartFor === 'regular' ? (
                                        <React.Fragment>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        </React.Fragment>
                                    ) : (
                                        <FiShoppingCart />
                                    )}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleItem;