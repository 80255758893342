import { Row, Col, Container } from "react-bootstrap";
import { Link } from 'react-router-dom'


const HomeStart = () => {

  

  return (
    <section className="page-2 banner-area d-flex align-items-center bg_cover border-bottom mw-sm-auto">
      <Container>
        <Row className="justify-content-center">
          <Col lg="11">
            <div className="text-center">
              <h1 className="display-5 fw-bold">DsCart - Digital Marketplace</h1>
              <div className="col-lg-6 mx-auto mb-4">
                <p className="lead mb-4">
                  Quickly design and customize responsive mobile-first sites with Bootstrap, the world’s most popular front-end open source toolkit, featuring Sass variables and mixins, responsive grid system, extensive prebuilt components, and powerful JavaScript plugins.
                </p>
                <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                  <Link to="/about" className="btn btn-primary btn-lg px-4 gap-3">About Us</Link>
                  <Link to="/search?q=&sortby=latest_product" type="button" className="btn btn-primary btn-lg px-4 gap-3">Products</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HomeStart;