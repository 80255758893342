import React, { useState } from 'react'
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import queryString from 'query-string';
const SearchBox = (props) => {

    let history = useNavigate();
    // get seach value
    const { search } = useLocation();
    let values = queryString.parse(search).q;
    let sortbyQ = queryString.parse(search).sortby;

    if (values === undefined) {
        values = "";
    }
    if (sortbyQ === undefined) {
        sortbyQ = "";
    }

    const [SearchValue, setSearchValue] = useState(values);

    const handleSubmit = (e) => {
        e.preventDefault();
        let q = SearchValue.replace(/\s+/g, '+');

        if (sortbyQ !== "") {
            sortbyQ = "&sortby=" + sortbyQ;
        }
        history.push("/search?q=" + q + sortbyQ);
    }

    const handleChange = (e) => {
        setSearchValue(e.target.value);
    }

    return (
        <React.Fragment>
            {props.show && (
                <div className="main-search">

                    <Container fluid>
                        <Row>
                            <Col className="header-search-box">
                                <Form onSubmit={(e) => { handleSubmit(e) }}>
                                    <InputGroup size="lg" >
                                        <Form.Control value={SearchValue} onChange={(e) => handleChange(e)} placeholder={"Search"} />
                                        <Button className="btn-outline-secondary px-5" type="submit">Search</Button>
                                    </InputGroup>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )}
        </React.Fragment>
    )
}

export default SearchBox