import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useEnv } from '../../support/env';
import { url } from '../../support/helper';
import { useSetting } from '../../support/setting';

export default function SeoForPage(props) {

    const [page, setPage] = useState({});
    const env = useEnv();
    const { settings } = useSetting();

    useEffect(() => {

    }, []);

    useEffect(() => {
        window.axios.get(`/seo?path=${window.location.pathname}`).then(response => {
            if (response && response.data) {

                var data = response.data;
                Object.keys(data).forEach(key => {
                    var line = data[key];
                    if (props.replaceWords && typeof props.replaceWords === "object") {
                        Object.keys(props.replaceWords).forEach(name => {
                            line = line.replace(`:${name}`, props.replaceWords[name]);
                        });
                    }
                    data[key] = line;
                });
                setPage(data);
            }
        }).catch(e => {

        });
    }, [props.replaceWords]);

    return Object.keys(page).length ? (
        <Helmet>
            {/* Primary Meta Tags */}
            <meta name="title" content={page.title} />
            <meta name="description" content={page.description} />
            <meta name="keywords" content={page.keywords} />
            <meta name="canonical" href={page.canonical} />
            {/* Open Graph / Facebook */}
            <meta property="og:type" content={page.og_type} />
            <meta property="og:url" content={page.og_url} />
            <meta property="og:title" content={page.og_title} />
            <meta property="og:description" content={page.og_description} />
            <meta property="og:image" content={page.og_image} />
            <meta property="og:site_name" content={env.APP_NAME} />
            {/* Twitter */}
            <meta property="twitter:card" content={page.twitter_card} />
            <meta property="twitter:url" content={page.twitter_url} />
            <meta property="twitter:title" content={page.twitter_title} />
            <meta property="twitter:description" content={page.twitter_description} />
            <meta property="twitter:image" content={page.twitter_image} />

            {/* schema Organization */}
            <script type='application/ld+json'>{JSON.stringify(
                {
                    "@context": "http://schema.org",
                    "@type": "Organization",
                    "name": env.APP_NAME,
                    "url": url(),
                    "sameAs": Object.values(_.pickBy(settings, function (value, key) {
                        return key.includes('social_');
                    })),
                    "logo": settings['site_logo']
                }
            )}</script>

            {/* schema WebSite */}
            {env.APP_NAME ? (
                <script type='application/ld+json'>{JSON.stringify(
                    {
                        "@context": "http://schema.org",
                        "@type": "WebSite",
                        "name": env.APP_NAME,
                        "url": url()
                    }
                )}</script>
            ) : null}
        </Helmet>
    ) : null;
}
