import React, {useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import SeoForPage from "../../includes/seo";
import './about.css';

function About(){

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <React.Fragment>
            <SeoForPage />
            <section className="page-title">
                <Container fluid={true}>
                    <Row >
                        <Col className="mx-5">
                            <h1>About Us</h1>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="about-mission-area">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 col-12 wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s'}}>
                        <div className="about-mission-content">
                        <span>01</span>
                        <h3 className="a-mission-title">GetUp, At a Glance</h3>
                        <p>
                        </p>
                        <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt'}}>GetUp Limited is a digital marketing agency, committed to providing different solutions to companies in Bangladesh and beyond. We focus on digital marketing, website development, graphics solutions, photography, videography, animation, and marketing strategy.</p>
                        <p dir="ltr" style={{lineHeight: '1.38', textAlign: 'justify', marginTop: '12pt', marginBottom: '12pt'}}>As a sister concern of Softifybd, we started our journey with a strong network and clientele. With our knowledge and infrastructure exchange, we are privileged to have the capacity to address marketing challenges from small and Medium Enterprises (SME) to Large Corporations. We believe in our ability to break the "ordinary" barriers to promotional content as our team consists of experienced, creative, and skilled minds.</p>
                        <p />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 wow fadeInRight" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                        <div className="about-mission-img text-center">
                        <img  src="/img/about/3.svg" alt="#" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="funfact-area">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-6 col-12 wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s'}}>
                        <div className="funfact-content">
                        <div className="funfact-content-top">
                            <p>JOIN THE CLUB OF VICTORY</p>
                            <h3 className="funfact-c-title">GetUp has helped in making many success stories</h3>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                            <div className="single-count">
                                <div className="main-count">
                                <h4><span className="counter">10k</span><strong>+</strong></h4>
                                <p>CLIENTS SERVED</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                            <div className="single-count">
                                <div className="main-count">
                                <h4><span className="counter">300</span><strong>+</strong></h4>
                                <p>ACTIVE PROJECTS</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 wow fadeInUp" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                            <div className="single-count">
                                <div className="main-count">
                                <h4><span className="counter">5k</span><strong>+</strong></h4>
                                <p>RETURNED CLIENTS</p>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 wow fadeInRight" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                        <div className="funfact-img text-center">
                        <img src="/img/about/2.svg" alt="achievements" />
                        </div>
                    </div>
                    </div>
                </div>
            </section>

            <section className="about-mission-area">
            <div className="container">
                <div className="row">
                <div className="col-lg-6 col-12 wow fadeInLeft" data-wow-delay="0.1s" style={{visibility: 'visible', animationDelay: '0.1s'}}>
                    <div className="about-mission-img">
                    <img  src="/img/about/1.svg" alt="#" />
                    </div>
                </div>
                <div className="col-lg-6 col-12 wow fadeInRight" data-wow-delay="0.2s" style={{visibility: 'visible', animationDelay: '0.2s'}}>
                    <div className="about-mission-content">
                    <span>02</span>
                    <h3 className="a-mission-title">Our Mission</h3>
                    <p>The market is filled with small to large agencies which are offering digital marketing solution to businesses. But unfortunately, most organizations are getting the fruit of professional solutions. That is because digital agencies are focusing on medium to large companies.
                        GetUp Limited believes that all dreams deserve to have the opportunity to express their ideas to the world. That is why we give priority to businesses and dreams of all sizes in all industries. Our mission is to reshape our business culture to efficient, professional, communicative, and world-class. GetUp Limited acknowledges that reshaping the culture of business in Bangladesh is not a one-man job. That is why we are creating a network of dreams so we can make a cooperative culture in our business environment. </p>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </React.Fragment>
    )};

export default About;