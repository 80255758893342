
import React from 'react'

export default function Empty() {
  return (
    <div className="text-center m-5">
      <svg width="20%" viewBox="0 0 181 181" fill="none" xmlns="://www.w3.org/2000/svg">
        <path d="M174.18 137.75L100.42 180.34L17.36 132.27L91.12 89.69L174.18 137.75Z" fill="white" />
        <path opacity="0.5" d="M174.18 137.75L100.42 180.34L17.36 132.27L91.12 89.69L174.18 137.75Z" fill="black" />
        <path d="M91.12 31.96V89.69L17.36 132.27V74.55L91.12 31.96Z" fill="white" />
        <path opacity="0.23" d="M91.12 31.96V89.69L17.36 132.27V74.55L91.12 31.96Z" fill="black" />
        <path d="M100.42 122.61V180.34L17.36 132.27V74.55L100.42 122.61Z" fill="white" />
        <path opacity="0.38" d="M100.42 122.61V180.34L17.36 132.27V74.55L100.42 122.61Z" fill="black" />
        <path d="M174.18 80.02V137.75L91.12 89.69V31.96L174.18 80.02Z" fill="white" />
        <path opacity="0.4" d="M174.18 80.02V137.75L91.12 89.69V31.96L174.18 80.02Z" fill="black" />
        <path d="M174.18 137.75V80.02L100.42 122.61V180.34L174.18 137.75Z" fill="white" />
        <path opacity="0.23" d="M174.18 137.75V80.02L100.42 122.61V180.34L174.18 137.75Z" fill="black" />
        <path d="M91.12 31.96L91.22 0L174.08 48.12L174.18 80.02L91.12 31.96Z" fill="white" />
        <path opacity="0.2" d="M91.12 31.96L91.22 0L174.08 48.12L174.18 80.02L91.12 31.96Z" fill="black" />
        <path d="M100.42 122.61L107.34 94.65L180.43 52.45L174.18 80.02L100.42 122.61Z" fill="white" />
        <path opacity="0.3" d="M100.42 122.61L107.34 94.65L180.43 52.45L174.18 80.02L100.42 122.61Z" fill="black" />
        <path d="M17.36 74.55L8.56 45.55L82.32 2.97L91.12 31.96L17.36 74.55Z" fill="white" />
        <path opacity="0.1" d="M17.36 74.55L8.56 45.55L82.32 2.97L91.12 31.96L17.36 74.55Z" fill="black" />
        <path d="M17.36 74.55L0 50.58L83.07 98.64L100.42 122.61L17.36 74.55Z" fill="white" />
        <path opacity="0.45" d="M17.36 74.55L0 50.58L83.07 98.64L100.42 122.61L17.36 74.55Z" fill="black" />
        <g opacity="0.7">
          <path opacity="0.7" d="M122.14 163.17C122.02 163.17 121.904 163.123 121.818 163.039C121.732 162.955 121.683 162.84 121.68 162.72V152.56C121.683 152.439 121.732 152.323 121.818 152.238C121.903 152.152 122.019 152.103 122.14 152.1C122.26 152.103 122.375 152.152 122.459 152.238C122.543 152.324 122.59 152.44 122.59 152.56V162.72C122.59 162.839 122.543 162.954 122.458 163.038C122.374 163.123 122.259 163.17 122.14 163.17Z" fill="white" />
          <path opacity="0.7" d="M120.81 156.08C120.75 156.089 120.69 156.089 120.63 156.08C120.521 156.03 120.435 155.939 120.391 155.828C120.346 155.716 120.346 155.592 120.39 155.48L122.01 151.75L123.81 153.83C123.874 153.923 123.902 154.037 123.888 154.149C123.874 154.261 123.819 154.365 123.734 154.439C123.648 154.513 123.538 154.553 123.425 154.552C123.312 154.55 123.203 154.507 123.12 154.43L122.27 153.43L121.27 155.83C121.227 155.913 121.16 155.98 121.078 156.025C120.996 156.069 120.903 156.089 120.81 156.08Z" fill="white" />
          <path opacity="0.7" d="M126.33 160.78C126.21 160.777 126.095 160.728 126.011 160.642C125.927 160.556 125.88 160.44 125.88 160.32V150.17C125.88 150.051 125.927 149.936 126.012 149.852C126.096 149.767 126.211 149.72 126.33 149.72C126.449 149.72 126.564 149.767 126.648 149.852C126.733 149.936 126.78 150.051 126.78 150.17V160.31C126.781 160.37 126.771 160.43 126.749 160.487C126.727 160.543 126.694 160.595 126.652 160.638C126.611 160.682 126.561 160.717 126.505 160.741C126.45 160.766 126.39 160.779 126.33 160.78Z" fill="white" />
          <path opacity="0.7" d="M125 153.69C124.94 153.699 124.88 153.699 124.82 153.69C124.765 153.666 124.716 153.632 124.674 153.588C124.633 153.545 124.6 153.494 124.579 153.438C124.558 153.382 124.548 153.323 124.549 153.263C124.551 153.203 124.565 153.144 124.59 153.09L126.2 149.31L128 151.38C128.083 151.466 128.13 151.581 128.13 151.7C128.13 151.819 128.083 151.934 128 152.02C127.958 152.062 127.908 152.096 127.853 152.119C127.798 152.142 127.74 152.154 127.68 152.154C127.62 152.154 127.562 152.142 127.507 152.119C127.452 152.096 127.402 152.062 127.36 152.02L126.51 151.02L125.51 153.42C125.469 153.516 125.395 153.596 125.303 153.645C125.21 153.694 125.103 153.71 125 153.69Z" fill="white" />
        </g>
        <g opacity="0.7">
          <path opacity="0.7" d="M110.05 170.06C109.879 170.063 109.71 170.021 109.56 169.94C109.379 169.824 109.233 169.662 109.136 169.47C109.039 169.278 108.996 169.064 109.01 168.85V167.6C109.013 167.48 109.062 167.365 109.148 167.281C109.234 167.197 109.35 167.15 109.47 167.15C109.589 167.15 109.704 167.197 109.788 167.282C109.873 167.366 109.92 167.481 109.92 167.6V168.85C109.92 169.03 109.92 169.13 110.01 169.15C110.1 169.17 110.15 169.15 110.3 169.07C110.581 168.81 110.803 168.492 110.95 168.138C111.097 167.784 111.165 167.403 111.15 167.02V161.82C111.15 161.701 111.197 161.586 111.282 161.502C111.366 161.417 111.481 161.37 111.6 161.37C111.719 161.37 111.834 161.417 111.918 161.502C112.003 161.586 112.05 161.701 112.05 161.82V167.02C112.072 167.56 111.967 168.097 111.743 168.589C111.519 169.081 111.182 169.513 110.76 169.85C110.546 169.982 110.301 170.054 110.05 170.06Z" fill="white" />
          <path opacity="0.7" d="M105.28 165.87V165.09C105.399 163.087 105.984 161.14 106.99 159.404C107.995 157.667 109.392 156.19 111.07 155.09C112.71 154.15 114.27 154.01 115.47 154.71C116.11 155.149 116.62 155.752 116.948 156.455C117.275 157.159 117.407 157.938 117.33 158.71V158.97L105.28 165.87ZM113.79 155.13C112.989 155.167 112.211 155.408 111.53 155.83C110.092 156.776 108.875 158.02 107.96 159.478C107.046 160.936 106.456 162.574 106.23 164.28L116.4 158.4C116.443 157.821 116.336 157.24 116.089 156.715C115.842 156.189 115.464 155.736 114.99 155.4C114.621 155.206 114.207 155.113 113.79 155.13Z" fill="white" />
        </g>
        <path opacity="0.7" d="M0 50.58L83.07 98.64L100.42 122.61L82.15 99.49L0 50.58Z" fill="white" />
        <path opacity="0.7" d="M174.18 80.02L100.42 122.61V180.34L101.8 123.77L174.18 80.02Z" fill="white" />
      </svg>
    </div>
  )
}
