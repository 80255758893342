import React from 'react';
import { styled } from "goober";

export const CardSpinner = (props) => {

  const Div = styled('div')`
    height: ${props.height?props.height: "50"}vh !important;
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 0 !important;
  `;

  return (
    <Div>
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Div>
  )
}
