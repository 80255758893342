import { useStore } from "../provides/app";
import { useAuth } from "./auth";
import { useSetting } from "./setting";

/**
 * 
 * @returns 
 */
export function useCart() {

    const { store, dispatch } = useStore();
    const { isAdmin } = useAuth();
    const cart = store.cart;

    const itemExists = function (id, licence_name) {
        for (const cdata of cart) {
            if (cdata.item_id === id && cdata.licence_name === licence_name) {
                return true;
            }
        }
        return false;
    }

    const cartQueue = message => new Promise(reject => {
        setTimeout(() => reject(message), 500)
    });

    const addToCart = function (item, licence_name) {

        return new Promise(async (resolve, reject) => {

            if (item.regular_price <= 1 || item.extended_price <= 0) {

                await cartQueue("This item is not allow").then(message => reject({ message }));

            } else if (isAdmin) {

                return await cartQueue("Admin Can not cart any item").then(message => reject({ message }));

            } else if (itemExists(item.id, licence_name)) {

                return await cartQueue("Item already exist").then(message => reject({ message }));

            } else if (cart.length > 15) {

                return await cartQueue("Can not add more then 15 item").then(message => reject({ message }));

            } else {

                return await window.axios.post("/cart", {
                    'item_id': item.id,
                    'licence_name': licence_name
                }).then(response => {
                    if (response.data) {

                        const {cart, message} = response.data;

                        if(cart){
                            dispatch({
                                type: 'SET_CART',
                                cart: cart,
                            });
                        }
                        return resolve({ message: message })
                    }

                    return reject({ message: "Fail to added" });

                }).catch(error => {

                    if (error.response === undefined) {
                        return reject({ message: "You are offline" });
                    } else if (error.response.data && error.response.data.message) {
                        return reject({ message: error.response.data.message });
                    }

                    return reject({ message: "Unable to added" });
                });
            }
        });
    }

    const removeFormCart = function (id, licence_name) {
        return new Promise(async (resolve, reject) => {

            if (itemExists(id, licence_name)) {

                await cartQueue("Item not avalable in your cart list").then(message => reject({ message }));

            } else {
                await window.axios.delete(`/cart/${id}`).then(r => {
                    window.axios.get('/cart').then(rs => {
                        if (rs.data) {
                            dispatch({
                                type: 'SET_CART',
                                cart: rs.data,
                            });
                            resolve({ message: rs.data.message });
                        }
                    }).catch(es => { reject(es) });
                }).catch(error => {
                    reject(error)
                });
            }
        });
    }

    const cartList = function () {

        let data = [], price = 0;

        for (const c of cart) {

            if (c.item) {
                c.price = price;
                if (c.licence_name === 'regular') {

                    price += c.item.regular_price
                    c.price = c.item.regular_price;

                } else if (c.licence_name === 'extended') {
                    price += c.item.extended_price
                    c.price = c.item.extended_price;
                }

                data.push(c)
            }
        }

        return { data, price };
    }

    return { cart: cartList(), itemExists, addToCart, removeFormCart };
}


export default function useCurrency() {

    let currency = "USD";
    const { settings } = useSetting();

    if (settings && settings['billing_currency']) {
        currency = settings['billing_currency'];
    }

    return currency;
}