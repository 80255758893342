import { useStore } from "../provides/app";

/**
 * 
 * @returns 
 */
 export function useDucor() {
    const { store } = useStore();
    return store.ducor;
}
