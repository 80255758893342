import React, { useEffect, useState } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import LoginComponent from './login';
import RegisterComponent from './register';
import ForgotPasswordComponent from './forgot-password';
import { useStore } from '../../provides/app';

export default function AuthComponent(props) {

    const [tab, setTab] = useState('login');
    const { store } = useStore();

    useEffect(() => {

        let timer1 = setTimeout(() => {
            setTab('login');
        }, 200);

        return () => clearTimeout(timer1);

    }, [props])

    return (
        <Modal
            show={props.show && !store.auth.check}
            onHide={() => props.onHide()}
        >
            <Modal.Body>
                <Row>
                    <Col className="social-bg">
                        {tab === 'login' && (
                            <LoginComponent updateTab={(tab) => { setTab({tab}) }} />
                        )}
                        {tab === 'register' && (
                            <RegisterComponent updateTab={(tab) => { setTab(tab) }} />
                        )}
                        {tab === 'forgot-password' && (
                            <ForgotPasswordComponent updateTab={(tab) => { setTab(tab) }} />
                        )}
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

