import { v5 as uuid } from 'uuid';
import { useStore } from '../provides/app';

export function get_guest_id(force = false) {

    if (!force && window.localStorage.getItem('DSCart_token') !== null) {
        return "";
    }

    let guest_id = window.localStorage.getItem('guest_id');
    if (guest_id === null) {
        guest_id = uuid();
        window.localStorage.setItem('guest_id', guest_id)
    }

    return guest_id;
}


/**
 * 
 * @returns 
 */
export function useAuth() {
    const { store, dispatch } = useStore();

    // check isGuest
    var isGuest = function () {

        if (store.auth && store.auth.check) {
            return false;
        }

        return true;
    }

    // check isAdmin
    var isAdmin = function () {
        if (store.auth.check && store.auth.role === 'admin') {
            return true;
        }
        return false;
    }

    // check isUser
    const isUser = function () {
        if (store.auth.check && store.auth.role === 'user') {
            return true;
        }
        return false;
    }

    return {
        auth: store.auth,
        dispatch,
        isAdmin: isAdmin(),
        isUser: isUser(),
        isGuest: isGuest(),
    };
}
