import React, { createContext, useContext, useState } from "react";

/** 
* create context
*/
export const ItemContext = createContext([{}, () => { }]);

/**
 * 
 * @param {*} props 
 * @returns 
 */
export function ItemProvider(props) {

    const ItemHook = useState({});

    return (
        <ItemContext.Provider value={ItemHook}>
            {props.children}
        </ItemContext.Provider>
    )
}

export function useItem() {
    return useContext(ItemContext);
}

export default ItemProvider;