import { useStore } from "../provides/app";

/**
 * 
 * @returns 
 */
export function useSetting() {
    const { store, dispatch } = useStore();
    const settings = store.settings;


    function updateSetting(keys, value = "") {

        var data = settings;

        if (typeof keys === 'string' || keys instanceof String) {

            data[keys] = value;
            dispatch({
                type: 'SETTING_SET',
                settings: data,
            });

            return true;

        } else if (typeof keys === 'object' &&
            !Array.isArray(keys) &&
            keys !== null) {

            if (Object.keys(keys).length) {
                for (const k of Object.keys(keys)) {
                    data[k] = keys[k];
                }
            }

            dispatch({
                type: 'SETTING_SET',
                settings: data,
            });

            return true;

        } else if (keys.constructor === Array && Array.isArray(keys) && keys.length) {

            for (let i = 0; i < keys.length; i++) {
                const k = keys[i];
                data[k.key] = keys[k];
            }

            dispatch({
                type: 'SETTING_SET',
                settings: data,
            });

            return true;
        }

        return false;
    }

    return { settings, dispatch, updateSetting: updateSetting };
}