import React, { useState } from 'react'
import { Image, Offcanvas } from 'react-bootstrap';
import { TiTimesOutline } from 'react-icons/ti';
import { Link } from 'react-router-dom';
import { useStore } from '../../provides/app';
import toast from 'react-hot-toast';
import { FiShoppingCart } from 'react-icons/fi';
import { useCart } from '../../support/cart';
import { useDucor } from '../../support/ducor';
import { __currency } from '../../support/helper';

export default function Cart(props) {

    const { store } = useStore();
    const ducor = useDucor();
    const { removeFormCart, cart } = useCart();
    const [data, setData] = useState([]);

    const handleCheckout = (e) => {

        if (store.auth.check === false && cart.price !== 0) {
            e.preventDefault();
            toast.success('Please login first!');
            props.showAuth();
        }

        if (cart.price === 0.00) {
            e.preventDefault();
        } else {
            props.showAuth();
        }
    }

    const removeCart = (id) => {

        setData([...data, id]);

        removeFormCart(id).then(response => {


        }).catch(error => {

            console.log(error);

        }).then(function () {
            setTimeout(() => {
                setData([...data.filter(cart_id => cart_id !== id)])
            }, 3000);
        });
    }

    return (
        <Offcanvas placement={ducor.dir !== 'rtl' ? "end" : 'start'} id="mini-cart-list" show={props.show} onHide={() => props.onHide()} backdrop={true}>
            <Offcanvas.Header className="border-bottom p-0" closeButton >
                <Offcanvas.Title className="p-3 m-0">Shopping cart</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {cart && cart.data && cart.data.length ? cart.data.map((cart,key) => (
                    <div className={`cart-item${data.includes(cart.id) ? " show" : ""}`} key={`ki7sc-${cart.id}-key-${key}`}>
                        <div className="flex-shrink-0 image-hover">
                            <Image src={cart.item.thumbnailUrl} alt="cart item" rounded />
                            <div className="overlay">
                                {data.includes(cart.id) ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : (
                                    <TiTimesOutline size="1.5em" onClick={() => { removeCart(cart.id) }} />
                                )}
                            </div>
                        </div>
                        <div className="flex-grow-1 ms-2">
                            {cart.item && (
                                <Link to={`/item/${cart.item.slug}`} className="item-name">
                                    {cart.item.name}
                                </Link>
                            )}

                            <div className="d-flex justify-content-between">
                                <div className="item-licence">Licence: {cart.licence_name}.</div>
                                <div className="item-price">{__currency(cart.price)}</div>
                            </div>
                        </div>
                    </div>
                )) : (
                    <div className="text-center" style={{ paddingTop: '37%' }}>
                        <FiShoppingCart size="12em" />
                        <p>No item were found matching your selection</p>
                    </div>
                )}
            </Offcanvas.Body>
            <div className="offcanvas-footer pb-3 px-3 border-top-1">
                <div className="d-grid gap-2">
                    <Link to="/checkout" onClick={(e) => handleCheckout(e)} className="btn btn-primary d-flex justify-content-between opacity-75-hover" disabled={cart.price ? cart.price : 0}>
                        <div> Proceed To Checkout </div>
                        <div> {' | '}{__currency(cart && cart.price ? cart.price : 0)} </div>
                    </Link>
                </div>
            </div>
        </Offcanvas>
    )
}
