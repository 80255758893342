
function Setting() {
    return {};
}

function SettingPublic() {
    return {};
}

export function Get() {

    if (window.localStorage.getItem("dscart_settings")) {
        return JSON.parse(window.localStorage.getItem("dscart_settings"));
    }
    return {};
}
export function Set(data) {

    // console.log(data);
    if (typeof data === 'object') {
        window.localStorage.setItem('dscart_settings', JSON.stringify(data));
    }

    return Get();
}

export default Setting;

Setting.Get = Get;
Setting.Set = Set;
Setting.SettingPublic = SettingPublic