import React, { useState, Fragment } from 'react'
import { Form, Button, Alert } from 'react-bootstrap';
import { useStore } from '../../../provides/app';

export default function LoginComponent(props) {

    const [disableForm, setDisableForm] = useState(false);
    const [error, setError] = useState(null);
    const { dispatch } = useStore();

    const handleSubmit = event => {

        event.preventDefault();
        setDisableForm(true);

        const formData = {
            email: event.currentTarget.email.value,
            password: event.currentTarget.password.value
        }

        //simplest
        window.axios.post('/login', formData).then(response => {

            if (response.data && response.data.auth && response.data.token) {

                const { auth, token } = response.data;
                dispatch({
                    type: 'AUTH_LOGIN',
                    data: {
                        id: auth.id,
                        name: auth.name,
                        email: auth.email,
                        status: auth.status,
                        role: auth.role,
                        avatar: auth.avatar,
                        created_at: auth.created_at,
                    }
                });
                dispatch({
                    type: 'AUTH_TOKEN_UPDATE',
                    token: "Bearer " + token
                });
                getCart();

                taxRate();
            }
        }).catch(function (error) {
            // login errors
            if (error && error.response && error.response.data &&
                error.response.data.errors) {

                const errors = error.response.data.errors;
                if (errors.email) {
                    setError(errors.email[0]);
                    dispatch({ type: 'AUTH_LOGOUT' });
                } else if (errors.password) {
                    setError(errors.password[0]);
                } else if (error.message) {
                    setError(error.message);
                } else {
                    setError(() => "Unknown Error");
                }
            } else if (error.message) {
                setError(error.message);
            } else {
                setError(() => "Unknown Error");
            }
        }).finally(() => {
            setDisableForm(false);
        });
    }

    const getCart = async () => {
        await window.axios.get('/cart').then(response => {
            if (response.data) {
                dispatch({
                    type: 'SET_CART',
                    cart: response.data,
                })
            }
        }).catch(error => {

        });
    }

    const taxRate = async () => {
        await window.axios.get('/tax').then(response => {
            if (response.data) {
                dispatch({
                    type: 'TAX_SET',
                    tax: response.data.tax,
                })
            }
        }).catch(error => {

        });
    }


    return (
        <div className="m-3">
            <h2 className="mb-3 text-center">Login</h2>
            <hr />
            {error && (<Alert variant="danger"> {error} </Alert>)}

            <Form className="pt-3" onSubmit={(e) => { handleSubmit(e) }}>
                <Form.Group className="mb-3">
                    <Form.Label>Username or email address </Form.Label>
                    <Form.Control name="email" disabled={disableForm} />
                    <small>User input looks like a normal email address such as 'janedoe@unknown.com' You can not Too Many Attempts.</small>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control name="password" type="password" disabled={disableForm} />
                </Form.Group>
                <div className="d-grid">
                    <Button type="submit" className="btn-xl btn-primary" disabled={disableForm}>
                        {disableForm && error === undefined ? (
                            <Fragment>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                Loading...
                            </Fragment>
                        ) : (
                            <span>Sign In</span>
                        )}
                    </Button>
                </div>
                <div className="my-3 my-sm-4 d-flex">
                    <a onClick={(e) => { e.preventDefault(); props.updateTab('forgot-password') }} href="#forgot-password" className="small text-gray-600 ms-auto mt-1">Forgot password?</a>
                </div>
                <div className="border-top border-gray-200 pt-3 pt-sm-4 text-center">
                    <span className="text-gray-700">You need an account?{` `}
                        <a onClick={(e) => { props.updateTab('register'); e.preventDefault() }} href="#register" className="link-primary">
                            Sign up
                        </a>
                    </span>
                </div>
            </Form>
        </div>
    )
}
