/**
 * update Auth api token
 */
const Locale = () => {

    if (window.localStorage.getItem("dscart_locale")) {
        return window.localStorage.getItem("dscart_locale");
    }

    return setLocale('en');
}

const setLocale = (locale = 'en') => {

    window.localStorage.setItem("dscart_locale", locale);
    return Locale();
}

Locale.Get = Locale;
Locale.Set = (locale) => setLocale(locale);

export default Locale;
