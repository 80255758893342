
import React, { useState, useEffect } from "react";
import { Row, Col, Button, Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link, useLocation } from "react-router-dom";
import Empty from "../../includes/empty";
import SingleItem from "../../includes/item";
import { CardSpinner } from "../../includes/loader/card";
import SeoForPage from "../../includes/seo";
import HomeEnd from "./homeEnd";
import HomeStart from "./homeStart";

export default function Home() {

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]);

  useEffect(() => {
    
    window.axios.get('/storefront/resent').then(response => {
      setData(response.data);

    }).catch(error => {
      if (error && error.response && error.response.message) {
        toast.error(error.response.message);
      }
    }).finally(() => {
      
      setIsLoading(false);
    });

    return () => {
      setData();
    };
  }, []);

  const getItem = () => {
    
    window.axios.get('/storefront/resent').then(response => {
      setData(response.data);

    }).catch(error => {
      if (error && error.response && error.response.message) {
        toast.error(error.response.message);
      }
    }).finally(() => {
      
      setIsLoading(false);
    });
  }

  return (
    <React.Fragment>

       <SeoForPage />

      {/* Hero  */}
      <HomeStart />

      <div className="page-2">
        <Container fluid={true}>
          <Row>
            <Col>
              <div className="section-head  text-center">
                <h2>Discover Our Latest Digital Goods</h2>
                <small> Meet our newbies! The latest website templates uploaded to the marketplace. </small>
              </div>
            </Col>
          </Row>
          <div className="marketplace-items item-3">
            {isLoading === false && data ? data.map(item =>
              <div className="item-style" key={`item_id-${item.id}`}>
                <SingleItem item={item} />
              </div>
            ) : (
              <div className="text-center">
                {data && data.length === 0 ? (<Empty />) : null}
              </div>
            )}
          </div>
          {isLoading ? (
            <div className="row p-5">
              <CardSpinner />
            </div>
          ) : (
            <Row className="pt-4 px-5">
              <Col sm="12" >
                <div className="text-center">
                  {isLoading === false && data === null ? (
                    <Button type="button" variant="outline-primary" onClick={() => getItem()}>Try again</Button>
                  ) : (
                    <Link to="/search?q=&sortby=latest_product" className="btn btn-outline-primary">View more new items</Link>
                  )}
                </div>
              </Col>
            </Row>
          )}
          <HomeEnd />
        </Container>
      </div>
    </React.Fragment>
  );

}
